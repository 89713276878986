.customer-service {
  .cs-contact-info__TEXT {
    .cs-contact-info {
      &__image {
        width: 19px;
      }
      &__title {
        margin-top: 12px;
      }
    }
  }
}
